import React from "react";
import {
  richTextWrapper,
  sectionWrapper,
} from "./style.mod.scss";
import { Container } from "react-bootstrap";
import RichText from "components/rich-text";

const RichTextSection = ({ data }) => {
  if (!data || !data.blockContent) return <></>;

  const { blockContent, bgColor } = data;

  const sectionStyle = {
    // color: color,
    backgroundColor: bgColor?.hex,
  };

  return (
    <div className={sectionWrapper} style={sectionStyle}>
      <Container>
        <RichText
          className={richTextWrapper}
          blocks={blockContent}
        />
      </Container>
    </div>
  );
};

export default RichTextSection;
